import { ref } from 'vue'
import useAuthentication from '~/composables/useAuthentication'
import { useMembershipsStore } from '~/stores/memberships'
import { asyncSome } from '~/utils/array'
import { hasPricingMenu } from '~/utils/memberships'
import { FORBIDDEN } from '~/constants/http'
import { SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES } from '~/constants/services/constants'

export const useAuthorization = () => {
  const router = useRouter()
  const membershipsStore = useMembershipsStore()
  const { isAuthenticated } = useAuthentication()
  const { $membershipApi } = useNuxtApp()
  const isAuthorized = ref(false)

  const hasMembership = async (membershipName: string) => {
    const authenticated = isAuthenticated()
    let isMembershipActiveValue =
      membershipsStore.isMembershipActive(membershipName)
    let memberships = []
    if (authenticated && !isMembershipActiveValue) {
      memberships = await $membershipApi.getActiveMemberships()
      isMembershipActiveValue = memberships.includes(membershipName)
    }
    if (
      !isMembershipActiveValue &&
      membershipName === SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES
    ) {
      isMembershipActiveValue = hasPricingMenu(memberships)
    }
    return isMembershipActiveValue
  }

  const hasMemberships = async (membershipNames: string[]) => {
    const memberships = await $membershipApi.getActiveMemberships(false)
    const isMembershipsValue = memberships.some((membership) =>
      membershipNames.includes(membership),
    )

    return isMembershipsValue && isAuthenticated()
  }

  const hasAuthorized = async (
    subscription: string,
    currentSubscription?: string,
  ) => {
    const authorized = await hasMembership(subscription ?? currentSubscription)
    return authorized && isAuthenticated()
  }

  const hasOneOfThisMemberships = (...memberships) =>
    asyncSome(memberships, hasMembership)

  const updateMembershipsAndRedirect = async (error, route) => {
    if (error?.status === FORBIDDEN || error.data?.status === FORBIDDEN) {
      await $membershipApi.getActiveMemberships()
      await router.push(route)
    }
  }

  return {
    isAuthorized,
    hasAuthorized,
    hasOneOfThisMemberships,
    hasMembership,
    hasMemberships,
    updateMembershipsAndRedirect,
  }
}
